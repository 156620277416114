import React, { useEffect, useState } from "react";
import LayoutLogged from "../../components/layout/logged";
import Container from '@material-ui/core/Container';
import { useForm } from "react-hook-form";
import AppForm from "../../components/form/app-form";
import UserFormAdmin from "../../components/pages/user-form-admin";
import StatementGrid from "../../components/dialog/statements/grid";
import { hasPermission, ifNotHaveAccessRedirect } from "../../services/auth";

export default function UserUpdate() {
  const { control, handleSubmit, setValue, formState: { errors } } = useForm();
  const [errorsApi, setErrorsApi] = useState(undefined);
  const [data, setData] = useState();
  const [disabled, setDisabled] = useState();

  useEffect(() => {
    if (!data) return;
    setDisabled(!checkEditPermission(data));
  }, [data]);

  const checkEditPermission = (data) => {
    if (data.roles.length === 0) {
      return true;
    }

    if (data.roles.find(role => role.name === 'referrer')) {
      return true;
    }

    if (data.roles.find(role => role.name === 'admin') && hasPermission('user.role.admin')) {
      return true;
    }

    if (data.roles.find(role => role.name === 'manager') && hasPermission('user.role.manager')) {
      return true;
    }

    if (data.roles.find(role => role.name === 'seller') && hasPermission('user.role.seller')) {
      return true;
    }

    if (data.roles.find(role => role.name === 'lecturer') && hasPermission('user.role.lecturer')) {
      return true;
    }

    return false;
  };

  if (ifNotHaveAccessRedirect('user.write')) return <></>;

  return (
    <LayoutLogged title={data?.name}>
      <Container>
        <AppForm
          api="/user"
          linksPath="/user"
          handleSubmit={handleSubmit}
          onErrors={setErrorsApi}
          onLoadData={setData}
          disabled={disabled}
          control
        >
          <UserFormAdmin
            control={control}
            setValue={setValue}
            errorsForm={errors}
            errorsApi={errorsApi}
            user={data}
            disabled={disabled}
          />

          <br /><br />
          <StatementGrid
            id={data?.id}
            from="user"
          />
        </AppForm>
      </Container>
    </LayoutLogged>
  );
}